export default defineNuxtRouteMiddleware(to => {
  const openFeature = useOpenFeature()

  for (const feature of to.meta.openFeatures as string[]) {
    // consider objects
    if (feature.includes('.')) {
      const [flag, key] = feature.split('.')
      const value = openFeature.getObject(flag, {})
      if (!value[key]) {
        console.log(`nope - ${feature}.${key} is not open!`)
        return navigateTo('/')
      }
    }

    if (!openFeature.getBool(feature, false)) {
      console.log(`nope - ${feature} is not open!`)
      return navigateTo('/')
    }
  }
})
